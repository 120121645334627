import React from "react";
import { Routes, Route } from "react-router-dom";
// import NavBar from "./components/navBar";
import Home from "./Pages/Home";
import Tarifs from "./Pages/Tarifs";
import Contact from "./Pages/Contact";
import Footer from "./components/Footer";
// import About from "./Pages/About";
import CGV from "./Pages/CGV";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="Contact" element={<Contact />} />
        <Route path="Tarifs" element={<Tarifs />} />
        {/* <Route path="/About" element={<About />} /> */}
        <Route path="/CGV" element={<CGV />} />
      </Routes>

      <Footer />
    </div>
  );
}
export default App;

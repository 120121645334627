import { Link } from "react-router-dom";
import { FaFacebook, FaInstagram } from "react-icons/fa";

function Footer() {
  return (
    <>
      <footer className="container-fluid" id="gtco-footer">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="row">
                <div className="col-3">
                  <h4>Company</h4>
                  <ul className="nav flex-column company-nav">
                    <li className="nav-item">
                      <Link to="/" className="nav-link">
                        About
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/CGV" className="nav-link">
                        CGV
                      </Link>
                    </li>

                    <li className="nav-item">
                      <Link to="/Contact" className="nav-link">
                        Contact
                      </Link>
                    </li>
                  </ul>
                </div>

                <div className="col-3">
                  <h4>Services</h4>
                  <ul className="nav flex-column services-nav">
                    <li className="nav-item">
                      <Link to="/Tarifs" className="nav-link">
                        Prestations
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="col-3">
                  <h4>Follow</h4>
                  <ul className="nav follow-us-nav">
                    <li className="nav-item">
                      <Link
                        to="https://www.instagram.com/kreativit67"
                        className="nav-link-insta"
                      >
                        <FaInstagram size={32} />
                      </Link>
                    </li>
                    <li className=" nav-item">
                      <Link
                        to="https://www.facebook.com/profile.php?id=61552158793569&locale=fr_FR"
                        className="nav-link"
                      >
                        <FaFacebook size={30} />
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="col-3">
                  <h4>Partenaires</h4>
                  <ul className="nav flex-column services-nav">
                    <li className="nav-item">
                      <Link
                        to="https://www.facebook.com/kiwaise67/?locale=fr_FR"
                        className="nav-link"
                      >
                        Kiwaise
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="col-12">
                  <p>
                    © 2023. Tous droits réservés à Kreativ'IT
                    {/* <a href="https://gettemplates.co" target="_blank">
                    GetTemplates
                  </a> */}
                    .
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}

export default Footer;

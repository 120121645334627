import React from "react";
import { Link } from "react-router-dom";
import "./Contact.css";
import "./Navbar2.css";
import "./Contact/css/animate.css";
import { useState } from "react";

// import { useForm, SubmitHandler } from "react-hook-form";
import emailjs from "@emailjs/browser";

import { FaPhone } from "react-icons/fa";
import { GrMail } from "react-icons/gr";
import { BsGlobe2 } from "react-icons/bs";
import { useRef } from "react";
import Navbar2 from "../components/Navbar2";

export const Contact = () => {
  const form = useRef();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [success, setSuccess] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_sbwbttk",
        "template_q8v6z8i",

        form.current,
        "GKajltZUShKSwNrvo"
      )
      .then((result) => {
        if (result.status === 200) setSuccess((s) => !s);
      });
    // .then(
    //   function (response) {
    //     response("SUCCESS!", response.status, response.text);
    //   },
    //   function (error) {
    //     error("FAILED...", error);
    //   }

    setName("");
    setEmail("");
    setSubject("");
    setMessage("");

    // .then(
    //   (result) => {
    //     console.log(result.text);
    //   },
    //   (error) => {
    //     console.log(error.text);
    //   }
    // );
  };

  return (
    <>
      <Navbar2 />
      <section className="ftco-section">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-6 text-center mb-5">
              {/* <h2 className="heading-section">Contact Form #03</h2> */}
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-md-12">
              <div className="wrapper">
                <div className="row mb-5">
                  <div className="col-1 mr-4">
                    <div className="dbox w-100 text-center">
                      {/* <div className="icon d-flex align-items-center justify-content-center">
                      <span className="fa fa-map-marker" />
                      </div>
                    <div className="text">
                      <p>
                        <span>Address:</span> 198 West 21th Street, Suite 721
                        New York NY 10016
                        </p>
                      </div> */}
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="dbox w-100 text-center">
                      <div className="icon d-flex align-items-center justify-content-center">
                        {/* <span className="fa fa-phone" /> */}
                        <FaPhone color="white" fontSize="1.5em" />
                      </div>
                      <div className="text-contact">
                        <p>
                          <span>Phone:</span>{" "}
                          <a href="tel://0749445141">07 49 44 51 41</a>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="dbox w-100 text-center">
                      <div className="icon d-flex align-items-center justify-content-center">
                        {/* <span className="fa fa-paper-plane" /> */}
                        <GrMail color="white" fontSize="1.5em" />
                      </div>
                      <div className="text-contact">
                        <p>
                          <span>Email:</span>{" "}
                          <a href="mailto:kreativit@outlook.fr">
                            kreativit@outlook.fr
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="dbox w-100 text-center">
                      <div className="icon d-flex align-items-center justify-content-center">
                        {/* <span className="fa fa-globe" /> */}
                        <BsGlobe2 color="white" fontSize="1.5em" />
                      </div>
                      <div className="text-contact">
                        <p>
                          <span>Site Web:</span>{" "}
                          <Link to="https://kreativit-it.fr">
                            kreativ-it.fr
                          </Link>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row no-gutters">
                  <div className="col-md-7">
                    <div className="contact-wrap w-100 p-md-5 p-4">
                      <h3 className="mb-4">Contact</h3>
                      <div id="message-warning" className="mb-4" />
                      <form ref={form} onSubmit={sendEmail}>
                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-group">
                              <label className="label" htmlFor="name">
                                Votre nom
                              </label>
                              <input
                                // {...register("name")}
                                type="text"
                                className="form-control"
                                name="name"
                                id="name"
                                placeholder="Nom / Prénom"
                                minlength="2"
                                value={name}
                                onChange={(event) =>
                                  setName(event.target.value)
                                }
                                required
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label className="label" htmlFor="email">
                                Votre adresse mail
                              </label>
                              <input
                                // {...register("email", { required: true })}
                                type="email"
                                className="form-control"
                                name="email"
                                id="email"
                                placeholder="Email"
                                value={email}
                                onChange={(event) =>
                                  setEmail(event.target.value)
                                }
                                required
                              />
                              {/* {errors.email && (
                              <p className="text-[red]">Email requis</p>
                            )} */}
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="form-group">
                              <label className="label" htmlFor="subject">
                                Sujet
                              </label>
                              <input
                                // {...register("subject")}
                                type="text"
                                className="form-control"
                                name="subject"
                                id="subject"
                                placeholder="Sujet du mail"
                                value={subject}
                                onChange={(event) =>
                                  setSubject(event.target.value)
                                }
                                required
                              />
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="form-group">
                              <label className="label" htmlFor="message">
                                Message
                              </label>
                              <textarea
                                // {...register("message")}
                                name="message"
                                className="form-control"
                                id="message"
                                cols={30}
                                rows={4}
                                placeholder="Message"
                                value={message}
                                onChange={(event) =>
                                  setMessage(event.target.value)
                                }
                                defaultValue={""}
                                required
                              />
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="form-group">
                              <input
                                type="submit"
                                defaultValue="Send Message"
                                className="btn btn-primary"
                              />
                              <div className="submitting" />
                            </div>
                            <div id="success" className="mb-4">
                              {success && (
                                <p>Votre message a bien été envoyé, Merci !</p>
                              )}
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                  <div className="col-md-5 d-flex align-items-stretch">
                    <div
                      className="info-wrap w-100 p-5 img"
                      style={{
                        backgroundImage: "url(/images/img-contact.jpg)",
                      }}
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Contact;
// const FormInputs = {
// subject: string;
// email: string;
// message: string;
// name: string;
// };
// export const Contact = () => {
//   const {
//     register,
//     handleSubmit,
//     formState: { errors },
//   } = useForm<FormInputs>();

//   const onSubmit: SubmitHandler<FormInputs> = (data) => {
//     emailjs.send(
//       "service_sbwbttk",
//       "template_q8v6z8i",
//       {
//         subject: data.subject,
//         email: data.email,
//         message: data.message,
//         name: data.name,
//       },
//       "GKajltZUShKSwNrvo"
//     );
//   };
//   return (
//     <footer className="container-fluid" id="gtco-footer">
//       <div className="container">
//         <div className="row"></div>
//         <div className="container">
//           <div className="col-lg-6" id="contact">
//             <h4> Contact Us </h4>
//             <input {...register(Name)}
//               type="text"
//               className="form-control"
//               placeholder="Name"

//             />
//             <input {...register("email", { required: true })}
//               type="email"
//               className="form-control"
//               placeholder="Email"
//             />   {errors.email && (
//               <p className="text-[red]">Email requis</p>
//             )}
//             <textarea  {...register("message")}
//               className="form-control"
//               placeholder="Message"
//               defaultValue={""}
//             />
//             <Link href="#" className="submit-button">
//               READ MORE <i className="fa fa-angle-right" aria-hidden="true" />
//             </Link>
//           </div>
//         </div>
//       </div>
//     </footer>
//   );
// }

// export default Contact;

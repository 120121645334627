import React from "react";
import Navbar2 from "../components/Navbar2";
import "./Navbar2.css";

function Tarifs() {
  return (
    <>
      <Navbar2 />

      <>
        <div className="container-fluid gtco-features-list">
          <div className="container">
            <div className="row">
              <div className="media col-md-6 col-lg-4">
                <div className="oval mr-4">
                  <img
                    className="align-self-start"
                    src="images/quality-results.png"
                    alt=""
                  />
                </div>
                <div className="media-body">
                  <h5 className="mb-0">Installation système informatique</h5>
                  Notre équipe d'experts en informatique est là pour vous aider
                  à l'installation de votre système informatique. Que vous soyez
                  un particulier ou une entreprise, nous nous chargeons de
                  configurer et d'installer tous les composants nécessaires,
                  depuis le système d'exploitation jusqu'aux logiciels
                  applicatifs. Faites confiance à notre expertise pour une
                  installation efficace et sans souci.
                  <div className="price">
                    <h6>A partir de 70€</h6>
                  </div>
                </div>
              </div>
              <div className="media col-md-6 col-lg-4">
                <div className="oval mr-4">
                  <img
                    className="align-self-start"
                    src="images/img-setting.png"
                    alt=""
                  />
                </div>
                <div className="media-body">
                  <h5 className="mb-0">Réparation / Dépannage à domicile</h5>
                  Vous rencontrez des problèmes avec votre ordinateur ? Notre
                  équipe de techniciens qualifiés se déplace chez vous pour
                  diagnostiquer et réparer tous types de pannes informatiques.
                  Que ce soit un problème de logiciel, de matériel ou de
                  connexion réseau, nous mettons tout en œuvre pour résoudre
                  rapidement vos soucis et vous permettre de retrouver un
                  ordinateur fonctionnel en un rien de temps.
                  <div className="price">
                    <h6>A partir de 55€</h6>
                  </div>
                </div>
              </div>
              <div className="media col-md-6 col-lg-4">
                <div className="oval mr-4">
                  <img
                    className="align-self-start"
                    src="images/affordable-pricing.png"
                    alt=""
                  />
                </div>
                <div className="media-body">
                  <h5 className="mb-0">Vente / montage PC</h5>
                  Besoin d'un nouvel ordinateur adapté à vos besoins spécifiques
                  ? Nous proposons un service de vente et de montage sur mesure
                  de PC. Que vous soyez un gamer passionné, un graphiste
                  professionnel ou un utilisateur quotidien, nous vous guiderons
                  dans le choix des composants les mieux adaptés à vos besoins
                  et nous assemblerons votre PC sur mesure pour des performances
                  optimales.
                  <div className="price">
                    <h6>A partir de 110€</h6>
                  </div>
                </div>
              </div>
              <div className="media col-md-6 col-lg-4">
                <div className="oval mr-4">
                  <img
                    className="align-self-start"
                    src="images/data.png"
                    // src="/image/easy-to-use.png"
                    alt=""
                  />
                </div>
                <div className="media-body">
                  <h5 className="mb-0">Récupération de données</h5>
                  La perte de données peut être désastreuse. Heureusement, notre
                  équipe est là pour vous aider à récupérer vos précieuses
                  informations. Nous utilisons des techniques avancées de
                  récupération de données pour restaurer vos fichiers perdus
                  suite à une panne matérielle, une suppression accidentelle ou
                  un problème informatique. Faites appel à nos experts et
                  retrouvez vos données en toute tranquillité.
                  <div className="price">
                    <h6>A partir de 25€</h6>
                  </div>
                </div>
              </div>
              <div className="media col-md-6 col-lg-4">
                <div className="oval mr-4">
                  <img
                    className="align-self-start"
                    src="images/router.png"
                    alt=""
                  />
                </div>
                <div className="media-body">
                  <h5 className="mb-0"> L'installation de box internet</h5>
                  Vous venez de souscrire à un abonnement internet, mais vous ne
                  savez pas comment installer votre box ? Ne vous inquiétez pas,
                  notre équipe est là pour vous guider. Nous prenons en charge
                  l'installation de votre box internet, du raccordement des
                  câbles à la configuration du réseau Wi-Fi. Profitez d'une
                  connexion internet stable et rapide grâce à notre expertise en
                  installation de box internet.
                  <div className="price">
                    <h6>A partir de 45€</h6>
                  </div>
                </div>
              </div>
              <div className="media col-md-6 col-lg-4">
                <div className="oval mr-4">
                  <img
                    className="align-self-start"
                    src="images/img-code.png"
                    alt=""
                  />
                </div>
                <div className="media-body">
                  <h5 className="mb-0">La création de site web </h5>
                  Besoin d'une présence en ligne pour votre entreprise ou votre
                  projet ? Nous vous proposons un service complet de création de
                  site web. Notre équipe de concepteurs web talentueux s'occupe
                  de chaque phase du processus de création, de la conception
                  graphique à la programmation et à l'optimisation (HTML / CSS /
                  JS / REACT). Obtenez un site web esthétique, fonctionnel et
                  parfaitement adapté à vos besoins.
                  <div className="price">
                    <h6>A partir de 199€</h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </>
  );
}

export default Tarifs;

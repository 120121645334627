import React from "react";
import { Link } from "react-router-dom";

function Navbar1() {
  return (
    <>
      <nav
        className="navbar navbar-expand-lg navbar-light bg-light bg-transparent"
        id="gtco-main-nav"
      >
        <div className="container">
          <Link to="/" className="navbar-brand">
            Kreativ'IT
          </Link>

          <button
            className="navbar-toggler"
            data-target="#my-nav"
            onclick="myFunction(this)"
            data-toggle="collapse"
          >
            <span className="bar1" /> <span className="bar2" />{" "}
            <span className="bar3" />
          </button>
          <div id="my-nav" className="collapse navbar-collapse">
            <ul className="navbar-nav mr-auto">
              <li className="nav-item">
                <Link to="/" className="nav-link" href="#">
                  Accueil
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/Tarifs" className="nav-link" href="#tarifs">
                  Prestations / Tarifs
                </Link>
              </li>
              {/* <li className="nav-item">
                <Link className="nav-link" href="#about">
                  About
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" href="#news">
                  News
                </Link>
              </li> */}
              <li className="nav-item">
                <Link to="/Contact" className="nav-link" href="#contact">
                  Contact
                </Link>
              </li>
            </ul>
            {/* <form className="form-inline my-2 my-lg-0">
              <Link
                href="#"
                className="btn btn-outline-dark my-2 my-sm-0 mr-3 text-uppercase"
                >
                login
                </Link>{" "}
                <Link
                href="#"
                className="btn btn-info my-2 my-sm-0 text-uppercase"
              >
              sign up
              </Link>
            </form> */}
          </div>
        </div>
      </nav>
    </>
  );
}

export default Navbar1;
